<template>
  <BlockContainer>
    <template v-if="!!data">
      <BlockHero :image="data.heroImage" />
      <BlockGradient>
        <div class="pt-10 px-10 pb-5 md:pt-20 md:px-20 md:pb-10">
          <h1 class="text-3xl">{{ data.title }}</h1>
        </div>
        <CoreRichText
          class="pb-10 px-10 md:pb-20 md:px-20 text-base h6-caption"
          :document="data.body"
        />
      </BlockGradient>
    </template>
  </BlockContainer>
</template>

<script>
import { getCompetition } from "@io";
import { BlockContainer, BlockHero, BlockGradient } from "@c/Block";

export default {
  name: "Competition",

  components: {
    BlockContainer,
    BlockHero,
    BlockGradient
  },

  data: () => ({
    data: null
  }),

  async created() {
    this.data = await getCompetition(this.$route.params.slug);
  }
};
</script>

<style scoped>
.h6-caption ::v-deep h6 {
  font-size: 0.8rem;
}
</style>
