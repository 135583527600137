<template>
  <BlockContainer>
    <div class="flex flex-wrap p-4">
      <BlockWideThumbnail
        v-for="(item, i) in orderedBlogPosts"
        :key="i"
        v-bind="item"
        @click="$router.push({ name: 'BlogItem', params: { slug: item.slug } })"
      />
    </div>
  </BlockContainer>
</template>

<script>
import { getPosts } from "@io";
import { BlockContainer, BlockWideThumbnail } from "@c/Block";

export default {
  name: "Blog",

  components: {
    BlockContainer,
    BlockWideThumbnail
  },

  data: () => ({
    data: null
  }),

  computed: {
    orderedBlogPosts() {
      if (!this.data) return;
      return [...this.data].sort((a, b) => new Date(b.date) - new Date(a.date));
    }
  },

  async created() {
    this.data = await getPosts();
  }
};
</script>
