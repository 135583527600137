<template>
  <BlockContainer>
    <template v-if="!!data">
      <BlockHero :image="data.heroImage" />
      <BlockGradient>
        <div class="pt-10 px-10 pb-5 md:pt-20 md:px-20 md:pb-10">
          <h1 class="text-3xl">{{ data.title }}</h1>
          <p>{{ formattedDate }}</p>
        </div>
        <CoreRichText
          class="pb-10 px-10 md:pb-20 md:px-20 text-base h6-caption h3-heading img-size"
          :document="data.body"
        />
      </BlockGradient>
    </template>
  </BlockContainer>
</template>

<script>
import { getPost } from "@io";
import { BlockContainer, BlockHero, BlockGradient } from "@c/Block";

export default {
  name: "BlogItem",

  components: {
    BlockContainer,
    BlockHero,
    BlockGradient
  },

  data: () => ({
    data: null
  }),

  computed: {
    formattedDate() {
      return new Date(this.data.date).toLocaleString("en-GB", {
        dateStyle: "long"
      });
    }
  },

  async created() {
    this.data = await getPost(this.$route.params.slug);
  }
};
</script>

<style scoped>
.h6-caption ::v-deep h6 {
  font-size: 0.8rem;
}

.h3-heading ::v-deep h3 {
  font-size: 1.25rem;
  font-weight: bold;
}

/* .img-size ::v-deep picture {
  width: 50%;
} */

.img-size ::v-deep img {
  width: unset;
  max-width: 100%;
}
</style>
